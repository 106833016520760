.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.regular-data-holder {
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.BankingBlockAboveSection {
  margin-bottom: 10px;
  font-weight: bold;
}

.table-caption {
  color: #007bff; /* Blue color for links */
  cursor: pointer;
}

.other {
  font-style: italic;
  color: #666;
}

.standard-table {
  width: 100%;
  border-collapse: collapse;
}

.standard-table th,
.standard-table td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.col-balance {
  width: 20%;
}

.balance {
  font-weight: bold;
}

.action-col {
  width: 10%;
}

.quick-menu-wrapper {
  position: relative;
  display: inline-block;
}

.quick-menu {
  cursor: pointer;
}

.quick-menu-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
  display: none;
}

.quick-menu-overlay-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.quick-menu-overlay-ul li {
  margin-bottom: 5px;
}

.lastQuickMenuLink {
  color: #007bff;
}
