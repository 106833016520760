.sbContainer{
    padding: 130px 20px;
    button {
		border-radius: 20px;
		border: 1px solid #AE275F;
		background-color: #AE275F;
		color: #FFFFFF;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 45px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
		cursor: pointer;
        margin-left: 10px;
        margin-bottom: 10px;

	}
}