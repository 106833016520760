/* Desktop View */
.nav {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100px;
    line-height: 100px;
    background: rgba(174, 39, 95);
    z-index: 100;
  }
  
  .nav_logo {
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    p {
      color: white;
      font-size: 25px;
      font-weight: 600;
    }
  }
  
  .nav_menu ul {
    display: flex;
    margin: 0;
  }
  
  .nav_menu ul li {
    list-style-type: none;
  }
  
  .nav_menu ul li .link {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    padding-bottom: 15px;
    margin: 0 25px;
  }
  
  .link:hover,
  .active {
    border-bottom: 2px solid #fff;
  }
  
  .nav_button .btn {
    width: 130px;
    height: 40px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.4);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s ease;
    color: #fff;
  }
  .btn_mobile{
    width: 130px;
    height: 40px;
    font-weight: 500;
    background: rgba(255, 255, 255, 0.4);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s ease;
    color: #fff;
    display: none;
    margin: 10px;
  }
  .btn:hover {
    background: rgba(255, 255, 255, 0.3);
  }
  
  .registerBtn {
    margin-left: 15px;
  }
  
  .btn.white_btn {
    background: rgba(255, 255, 255, 0.7);
  }
  
  .btn.btn.white_btn:hover {
    background: rgba(211, 255, 255, 0.5);
  }
  
  .nav_menu_btn {
    display: none;
  }
  
  .user {
    text-decoration: none;
    font-weight: 500;
    color: #fff;
    padding-bottom: 15px;
    margin: 0 25px;
  }
  
  .userName{
    display: none;
  }
  
  /* Mobile View */
  @media (max-width: 648px) {
    .nav {
      height: 80px;
      padding: 0 10px;
    }
  
    .nav_logo img {
      width: 45px;
      height: 45px;
    }
  
    .nav_menu {
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background: rgb(218, 62, 127);
      transition: all 0.5s ease;
      z-index: 1;
      padding-left: 0;
    }
  
    .nav_menu.active {
      display: block;
      opacity: 1;
    }
  
    .nav_menu ul {
      flex-direction: column;
      padding-left: 0;
    }
  
    .nav_menu ul li {
    //   padding: 15px 0;
      margin: 0;
      height: 40px;
    }
   
    .nav_menu_btn {
      display: block;
      cursor: pointer;
    }
    .btn_mobile{
        width: 130px;
        height: 40px;
        font-weight: 500;
        background: rgba(255, 255, 255, 0.4);
        border: none;
        border-radius: 30px;
        cursor: pointer;
        transition: 0.3s ease;
        color: #fff;
        display: block;
        margin-top: 40px;
      }
    .nav_menu_btn .menu-icon {
      width: 45px;
      height: 45px;
    }
  
    .nav_button {
      display: none;
    }
  
    .user {
      display: none;
    }
  .userName{
    display: block;
  }
    .btn.white_btn {
      background: rgba(255, 255, 255, 0.7);
      align-self: center;
      margin-top: 20px;
      width: 80%;
    }
  
    .btn.btn.white_btn:hover {
      background: rgba(211, 255, 255, 0.5);
    }
  }
  