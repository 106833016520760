.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
  }
  
  .modal h2 {
    margin-bottom: 10px;
  }
  
  .modal button {
    border-radius: 20px;
    border: 1px solid #AE275F;
    background-color: #AE275F;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    cursor: pointer;
    margin: 10px;
  }
  