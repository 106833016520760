

.section{
	background: #f6f5f7;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
	margin: -20px 0 50px;
}
.container {
	background-color: #fff;
	border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
	position: relative;
	overflow: hidden;
	width: 768px;
	max-width: 100%;
	min-height: 480px;
	h1 {
		font-weight: bold;
		margin: 0;
	}
	
	h2 {
		text-align: center;
	}
	
	p {
		font-size: 14px;
		font-weight: 100;
		line-height: 20px;
		letter-spacing: 0.5px;
		margin: 20px 0 30px;
	}
	
	span {
		font-size: 12px;
	}
	
	a {
		color: #333;
		font-size: 14px;
		text-decoration: none;
		margin: 15px 0;
	}
	
	button {
		border-radius: 20px;
		border: 1px solid #AE275F;
		background-color: #AE275F;
		color: #FFFFFF;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 45px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
		cursor: pointer;
	}
	
	button:active {
		transform: scale(0.95);
	}
	
	button:focus {
		outline: none;
	}
	
	button.ghost {
		background-color: transparent;
		border-color: #FFFFFF;
	}
	
	form {
		background-color: #FFFFFF;
		display: flex;
		background-color: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 50px;
		height: 100%;
		text-align: center;
	}
	
	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 100%;
	}
}

.form_container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.sign_in_container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right_panel_active .sign_in_container {
	transform: translateX(100%);
}

.sign_up_container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.container.right_panel_active .sign_up_container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%, 49.99% {
		opacity: 0;
		z-index: 1;
	}
	
	50%, 100% {
		opacity: 1;
		z-index: 5;
	}
}

.overlay_container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.container.right_panel_active .overlay_container{
	transform: translateX(-100%);
}

.overlay {
	background: #AE275F; /* Fallback for older browsers */
    background: -webkit-linear-gradient(to right, #AE275F, #b12e64); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #AE275F, #b12e64); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-position: 0 0;
	color: #FFFFFF;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
  	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.container.right_panel_active .overlay {
  	transform: translateX(50%);
}

.overlay_panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.overlay_left {
	transform: translateX(-20%);
}

.container.right_panel_active .overlay_left {
	transform: translateX(0);
}

.overlay_right {
	right: 0;
	transform: translateX(0);
}

.container.right_panel_active .overlay_right {
	transform: translateX(20%);
}

.social_container {
	margin: 20px 0;
}

.social_container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline_flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

.footer p {
    margin: 10px 0;
}

.footer i {
    color: #AE275F;
}

.footer a {
    color: #3c97bf;
    text-decoration: none;
}

@media (max-width: 768px) {
	.container {
	  max-width: 90%;
	}
  
	.form_container {
	  width: 100%;
	}
  
	.sign_in_container,
	.sign_up_container {
	  width: 100%;
	}
  
	.overlay_container {
	  left: 0;
	  width: 100%;
	  display: none;
	}
  
	.overlay {
	  width: 100%;
	}
  
	.overlay_panel {
	  width: 100%;
	}
  }
  
  @media (max-width: 480px) {
	.container {
	  min-height: 100vh;
	  margin: 0;
	  border-radius: 0;
	}
  
	.form_container {
	  width: 100%;
	}
  
	.sign_in_container,
	.sign_up_container {
	  width: 100%;
	}
  
	.overlay_container {
	  left: 0;
	  width: 100%;
	  display: none;
	}
  
	.overlay {
	  width: 100%;
	}
  
	.overlay_panel {
	  width: 100%;
	}
  }

