.conversionContainer{
	padding: 130px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-weight: 500;
        font-size: 28px;
    }
    h2{
        font-weight: 500;
        font-size: 22px;
    }
    button {
		border-radius: 20px;
		border: 1px solid #AE275F;
		background-color: #AE275F;
		color: #FFFFFF;
		font-size: 12px;
		font-weight: bold;
		padding: 12px 45px;
		letter-spacing: 1px;
		text-transform: uppercase;
		transition: transform 80ms ease-in;
		cursor: pointer;
		margin: 10px;
		margin-bottom: 10px
	}
	input {
		background-color: #eee;
		border: none;
		padding: 12px 15px;
		margin: 8px 0;
		width: 100%;
	}
	label {
        font-weight: 500;
        font-size: 20px;
    // color: #fff;
    // padding-bottom: 15px;
    // margin: 0 25px;
    }
}