.home-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .link-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
  
  .custom-link {
    display: block;
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
  }
  
  .custom-link:hover {
    background-color: #ccc;
  }